import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ImageSkeleton = ({
  src,
  width,
  style = {},
  className,
  imageStyle = {},
  skeletonClassName,
}) => {
  const [loadImage, setLoadimage] = useState(false);

  // handle load image
  const handleLoadImage = (e) => {
    setLoadimage(true);
  };

  useEffect(() => {
    window.getHeight(skeletonClassName, style);
  }, []);

  return (
    <div style={{ height: imageStyle?.height }}>
      {!loadImage ? (
        <Skeleton.Avatar
          style={{ width: "100%", height: "100%" }}
          shape="square"
          size={width || "default"}
          active
          className={skeletonClassName}
        ></Skeleton.Avatar>
      ) : (
        // <LazyLoadImage
        //   src={src}
        //   effect="blur"
        //   placeholderSrc={getImage()}
        //   className={className}
        //   width="100%"
        //   style={imageStyle}
        //   height="auto"
        // />
        detectFileType(src) ==='image' ?
        <img
        src={src}
        alt="product"
        width="100%"
        height="auto"
        style={imageStyle}
        className={className}
      />:  <video
        autoPlay
        loop
        width="100%"
        height="auto"
        style={{
          aspectRatio: "5/7",
          objectFit: "cover",
        }}
        className={className}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
        
      )}
      <img
        className="d-none"
        src={src}
        alt="product"
        onLoad={handleLoadImage}
      />
      {/* <LazyLoadImage
          src={src}
          effect="blur"
          placeholderSrc={getImage()}
          className={className}
          width="100%"
          style={imageStyle}
          height="auto"
        /> */}
    </div>
  );
};

export default ImageSkeleton;


const detectFileType = (url) => {
  // Define image and video file extensions
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
  const videoExtensions = ['mp4', 'avi', 'mov', 'mkv', 'webm'];

  // Extract file extension from URL
  const extension = url?.split('.').pop().toLowerCase();

  // Check if the file extension is an image or video type
  if (imageExtensions.includes(extension)) {
    return 'image';
  } else if (videoExtensions.includes(extension)) {
    return 'video';
  } else {
    return 'unknown';
  }
};